import React from 'react';
import { Link } from 'react-router-dom';

const Services = () => {
    return (<>
        <section id="services" className="services sections-bg">
            <div className="container" data-aos="fade-up">

                <div className="section-header">
                    <h2>Book our Services today and connect with Raj Jyotishi Pandit Mukesh Shastri!</h2>
                    <p>Get to know about your future today by using the following services</p>
                </div>

                <div className="row gy-4" data-aos="fade-up" data-aos-delay="100">

                    <div className="col-lg-4 col-md-6">
                        <div className="service-item  position-relative">
                            <div className="icon">
                                <i class="bi bi-activity"></i>
                            </div>
                            <h3>Personalised Kundali</h3>
                            <p>Discover your destiny with a personalized Kundali. Share your birth details, and our expert astrologers will unveil your unique cosmic blueprint.</p>
                            <Link to={"/personalisedKundali"} className="readmore stretched-link">Book Now <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="service-item position-relative">
                            <div className="icon">
                                <i className="bi bi-broadcast"></i>
                            </div>
                            <h3>Kundali Matching</h3>
                            <p>Unlock cosmic compatibility with our horoscope matching service. Discover the stars influence on your relationship's harmony and future together.</p>
                            <Link to={'/kundaliMatching'} className="readmore stretched-link">Book Now <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="service-item position-relative">
                            <div className="icon">
                                <i className="bi bi-telephone"></i>
                            </div>
                            <h3>Telephonic Consultation</h3>
                            <p>Connect with our experienced astrologer for personalized insights via telephone consultation. Gain clarity, guidance, and answers to life's questions</p>
                            <button onClick={() => window.open(process.env.REACT_APP_TELEPHONIC_CONSULTATION, "_blank", "noreferrer")} className="readmore stretched-link">Book Now <i className="bi bi-arrow-right"></i></button>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="service-item position-relative">
                            <div className="icon">
                                <i className="bi bi-camera-video"></i>
                            </div>
                            <h3>Video Consultation</h3>
                            <p>Experience personalized astrology guidance through video consultation with our expert astrologers. Seek insights, clarity, and solutions from the comfort of your home.</p>
                            <button onClick={() => window.open(process.env.REACT_APP_ONLINE_CONSULTATION_LINK, "_blank", "noreferrer")} className="readmore stretched-link">Book Now <i className="bi bi-arrow-right"></i></button>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="service-item position-relative">
                            <div className="icon">
                                <i className="bi bi-calendar4-week"></i>
                            </div>
                            <h3>Online Pooja</h3>
                            <p>Participate in sacred rituals and online poojas guided by our astrologers. Experience spiritual blessings and divine connections from anywhere.</p>
                            <Link to={`/service/online-poojas`} className="readmore stretched-link">Book Now <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="service-item position-relative">
                            <div className="icon">
                                <i className="bi bi-chat-square-text"></i>
                            </div>
                            <h3>Online Muhurat</h3>
                            <p>Unlock auspicious timings and muhurats online through our astrology website. Access personalized guidance for your important life events and decisions.</p>
                            <Link to={`/service/online-muhurats`} className="readmore stretched-link">Book Now <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>

                </div>

            </div>
        </section></>)
}

export default Services;