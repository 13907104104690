import React, { useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import supabase from '../utils/connectSupabase';

const PersonalisedKundali = () => {
    const [pKundaliDetails, setPKundaliDetails] = useState({ name: '', email: '', phone: '', date_of_birth: '', time_of_birth: '', place_of_birth: '' });
    // const [messageSent, setMessageSent] = useState(false);

    const submitPersonalizedKundaliDetails = async (event) => {
        event.preventDefault();
        const { status } = await supabase.from('personalised-kundali').insert({ ...pKundaliDetails });
        if (status === 201) {
            // setMessageSent(true)
            window.open(process.env.REACT_APP_PERSONALISED_KUNDALI_LINK, '_blank')
        }

    }

    const handleSubmitpKundaliData = (event) => {
        setPKundaliDetails({ ...pKundaliDetails, [event.target.name]: event.target.value })
    }
    return <>
        <Header />
        <div className="service">
            <div className="serviceCard">
                <h3>Submit your details</h3>
                <div>
                    <form onSubmit={submitPersonalizedKundaliDetails} method="post" role="form" class="php-email-form">
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" onChange={handleSubmitpKundaliData} required />
                            </div>
                            <div class="col-md-6 form-group mt-3 mt-md-0">
                                <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" onChange={handleSubmitpKundaliData} required />
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6 form-group">
                                <input type="time" name="time_of_birth" class="form-control" id="time_of_birth" placeholder="Time of Birth" onChange={handleSubmitpKundaliData} required />
                            </div>
                            <div class="col-md-6 form-group mt-3 mt-md-0">
                                <input type="date" class="form-control" name="date_of_birth" id="date_of_birth" placeholder="Date of Birth" onChange={handleSubmitpKundaliData} required />
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6 form-group">
                                <input type="number" class="form-control" name="phone" id="phone" placeholder="Phone" onChange={handleSubmitpKundaliData} required />
                            </div>
                            <div class="col-md-6 form-group mt-3 mt-md-0">
                                <input type="text" name="place_of_birth" class="form-control" id="place_of_birth" placeholder="Place of Birth" onChange={handleSubmitpKundaliData} required />
                            </div>
                        </div>
                        <button className="mt-3 serviceCardSubmitButton" type="submit">Send</button>
                    </form>
                </div>
            </div>
            <p>Personalized Kundali Matching, a sacred practice in Hindu culture, analyzes birth charts for marital harmony. Our expert astrologers offer a comprehensive Kundli Milan service, assessing key factors like Varan, Vasya, Tara, Yoni, and more. With a detailed analysis, we ensure compatibility and bliss in your marital journey. Trust our accurate and reliable Kundali Matching service to pave the way for a harmonious and prosperous married life</p>
        </div>
        <Footer />
    </>
}

export default PersonalisedKundali;