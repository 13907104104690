import React, { useEffect, useState } from 'react';
import cosmic from '../../utils/cosmicConnect'

const Products = () => {
    const [gems, setGems] = useState([]);

    async function updateGemsValue() {

        const resp = await cosmic.objects.find({
            type: 'gems'
        })

        setGems(resp.objects)
    }

    useEffect(() => {
        updateGemsValue();
    }, [])
    // fetch gems from the dashboard.
    return (
        <section id="gems" class="portfolio sections-bg">
            <div class="container" data-aos="fade-up">

                <div class="section-header">
                    <h2>Gems</h2>
                    <p>Buy the recommended gems from us.</p>
                </div>

                <div class="portfolio-isotope" data-portfolio-filter="*" data-portfolio-layout="masonry"
                    data-portfolio-sort="original-order" data-aos="fade-up" data-aos-delay="100">

                    <div>
                        <ul class="portfolio-flters">
                            <li data-filter="*" class="filter-active">All Gems</li>
                        </ul>
                    </div>

                    <div class="row gy-4 portfolio-container-123">

                        {gems.map((gem) => {
                            return <div key={gem.bucket} class="col-xl-4 col-md-6 portfolio-item filter-app">
                                <div class="portfolio-wrap">
                                    <a href="#" class="glightbox"><img
                                        src={gem.metadata.gem_image.url} class="img-fluid" alt="" /></a>
                                    <div class="portfolio-info">
                                        <h4><a href="#" title="More Details">{gem.metadata.gem_name}</a></h4>
                                        <p>{gem.metadata.gem_details}</p>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Products;