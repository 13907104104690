import React from 'react';

const About = () => {
    return (
        <section id="about" className="about">
            <div className="container" data-aos="fade-up">

                <div className="section-header">
                    <h2>About Renowned Astrologer Raj Jyotishi Pandit Mukesh Shastri</h2>
                    <p>Raj Jyothshi Jaipur, Rajasthan</p>
                </div>

                <div className="row gy-4">
                    <div className="col-lg-6">
                        <h3>Renowned Astrologer</h3>
                        <img src="assets/img/about.jpg" className="img-fluid rounded-4 mb-4" alt="" />
                        <p>Populary known for his precise predictions for over 300,0000 customers with more than 37+ years of experience & 100 years of Legacy. Astrologer Mukesh Shastri is currently delivering daily episodes and has delivered over 3000 Live Episodes on Astrology, Numerology & Vastu on National & Regional TV Channels.</p>
                        {/* <p>Temporibus nihil enim deserunt sed ea. Provident sit expedita aut cupiditate nihil vitae quo officia vel.
                            Blanditiis eligendi possimus et in cum. Quidem eos ut sint rem veniam qui. Ut ut repellendus nobis tempore
                            doloribus debitis explicabo similique sit. Accusantium sed ut omnis beatae neque deleniti repellendus.</p> */}
                    </div>
                    <div className="col-lg-6">
                        <div className="content ps-0 ps-lg-5">
                            {/* <p className="fst-italic">

                            </p> */}
                            <ul>
                                <li><i className="bi bi-google"></i>4.9 rating out of 5 on Google.
                                </li>
                                <li><i className="bi bi-youtube"></i> Over 115k youtube subscribers.
                                </li>
                                <li><i className="bi bi-tv"></i>Famous Host of Goodluck Tip</li>
                            </ul>
                            <p>
                                Know about your future today!
                            </p>

                            {/* <div className="position-relative mt-4">
                                <img src="assets/img/about-2.jpg" className="img-fluid rounded-4" alt="" />
                                <a href="https://www.youtube.com/watch?v=LXb3EKWsInQ" className="glightbox play-btn"></a>
                            </div> */}
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default About;