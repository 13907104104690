import React, { useEffect, useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Link, useNavigate } from 'react-router-dom';
import cosmic from '../utils/cosmicConnect';
import { useParams } from 'react-router-dom';
import "./index.css";

const ServicePayment = () => {
    const [poojas, setOnlinePoojas] = useState([]);
    const navigate = useNavigate();
    let { id } = useParams();

    async function fetchServiceCards() {

        const resp = await cosmic.objects.find({
            type: id
        })

        console.log("resp", resp)
        setOnlinePoojas(resp.objects)
    }

    useEffect(() => {
        fetchServiceCards();
    }, [])

    const handleNavigateToPayment = (e, paymentLink) => {
        console.log("test", paymentLink)
        window.open(paymentLink, "_blank", "noreferrer");
    }

    return <>
        <Header />

        <div className="service">
            {poojas.map((pooja) => {
                return (
                    <div className="service-card-item">
                        <img src={pooja.metadata?.service_image.url} />
                        <h3>{pooja.metadata?.service_name} </h3>

                        <p>{pooja.metadata?.service_details}</p>

                        <h6>{pooja.metadata?.service_price}</h6>

                        <button className="readmore stretched-link" onClick={(e) => handleNavigateToPayment(e, pooja.metadata?.service_price_link)}>Book Now</button>
                    </div>
                )
            })}

        </div>
        <Footer />
    </>
}

export default ServicePayment;