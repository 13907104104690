import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../components/header"
import Footer from '../components/footer';
import "../index.css"

const PaymentSuccess = () => {
    const navigate = useNavigate();

    const handleGoToHome = () => {
        navigate('/#services');
    };

    return (
        <>
            <Header />
            <div className="flex flex-col items-center justify-center h-screen bg-gray-100 payment-results">
                <div className="bg-white p-8 rounded-lg shadow-md">
                    <h1 className="text-4xl font-bold text-green-500 mb-4">Payment Successful!</h1>
                    <p className="text-gray-700 mb-6">Thank you for your payment. Your transaction has been completed successfully.</p>
                    <button
                        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                        onClick={handleGoToHome}
                    >
                        Explore more services
                    </button>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PaymentSuccess;