import React, { useEffect, useState } from 'react';
import cosmic from '../../utils/cosmicConnect'
import { Link } from 'react-router-dom';

const Blog = () => {
    const [blogs, setBlogs] = useState([]);

    async function updateBlogs() {

        const resp = await cosmic.objects.find({
            type: 'blogs'
        })

        setBlogs(resp.objects)
    }

    useEffect(() => {
        updateBlogs();
    }, [])
    return (
        <section id="recent-posts" class="recent-posts sections-bg">
            <div class="container" data-aos="fade-up">

                <div class="section-header">
                    <h2>Recent Daily Updates</h2>
                    <p>Read about astrology daily updates here.</p>
                </div>

                <div class="row gy-4">
                    {blogs.slice(0, 3).map((blog) => {
                        return <div class="col-xl-4 col-md-6">
                            <article>

                                <div class="post-img">
                                    <img src={blog.metadata.blog_image?.url} alt="" class="img-fluid" />
                                </div>

                                <p class="post-category">{blog.metadata.blog_heading}</p>

                                <h2 class="title">
                                    <Link to={`/article/${blog.slug}`}>{blog.metadata.blog_heading}</Link>
                                </h2>

                                <div class="d-flex align-items-center">
                                    <img src="assets/img/blog/blog-author.jpg" alt="" class="img-fluid post-author-img flex-shrink-0" />
                                    <div class="post-meta">
                                        <p class="post-author">Raj Jyotishi Pandit Mukesh Shastri.</p>
                                        <p class="post-date">
                                            <time datetime="2022-01-01">{convertDateTime(blog.published_at)}</time>
                                        </p>
                                    </div>
                                </div>

                            </article>
                        </div>
                    })}
                </div>
            </div>
        </section>
    )
}

const convertDateTime = (date) => {
    const dateTime = new Date(date);

    // Format the date and time
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDateTime = dateTime.toLocaleString('en-US', options);

    return formattedDateTime;
}

export default Blog;