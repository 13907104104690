import React from 'react';

const HeroBanner = () => {
    const phoneNumber = process.env.REACT_APP_ASTROLOGER_CONTACT_NUMBER; // Replace with your actual phone number
    const message = 'Thank for connecting with Raj Jyotishi Pandit Mukesh Shastri Ji. Please share your query with Pandit Ji.'; // Message to pre-fill
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    return (
        <section id="hero" className="hero">
            <div className="container position-relative">
                <div className="row gy-5" data-aos="fade-in">
                    <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center text-center text-lg-start">
                        <h2>Raj Jyotishi <span>Pandit Mukesh Shastri</span></h2>
                        <p>Unlock the mysteries of your destiny.
                        </p>
                        <div className="d-flex justify-content-center justify-content-lg-start">
                            <a href="#about" className="btn-get-started">Get Started</a>
                            <a href={whatsappLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', marginLeft: '12px' }}>
                                <button className='btn-get-started'>
                                    <i style={{ marginRight: '4px' }} class="bi bi-whatsapp"></i>
                                    Connect on WhatsApp!
                                </button>
                            </a>
                        </div>
                        <br />
                        <div className="d-flex justify-content-center justify-content-lg-start">
                            <a href="https://www.youtube.com/@PanditMukeshShastri"
                                className="glightbox btn-get-started d-flex align-items-center"><i className="bi bi-play-circle"></i><span>Checkout Youtube</span></a>
                            <div className='d-flex justify-content-center justify-content-lg-start' style={{ textDecoration: 'none', marginLeft: '12px' }}>
                                <a href="/gallery" className="glightbox btn-get-started d-flex align-items-center "><i className='bi bi-images'></i>Checkout Gallery!</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2">
                        <img src="./assets/astrologer-photo.png" className="img-fluid" alt="" data-aos="zoom-out" data-aos-delay="100" />
                    </div>
                </div>
            </div>
        </section >
    )
}


export default HeroBanner;