import React, { useState } from 'react';
import supabase from '../../utils/connectSupabase';
import sendMessageToWhatsapp from '../utils/sendMessaageToWhatsapp';

const Contact = () => {
    const [contactData, setContactData] = useState({ name: '', email: '', subject: '', message: '' });
    const [messageSent, setMessageSent] = useState(false);

    const addContactData = async (event) => {
        event.preventDefault();
        const { status } = await supabase.from('contact').insert({ ...contactData });
        if (status === 201) {
            setMessageSent(true)
            sendMessageToWhatsapp(contactData)
        }
    }

    const handleSubmitContactData = (event) => {
        setContactData({ ...contactData, [event.target.name]: event.target.value })
    }

    return (
        <section id="contact" class="contact">
            <div class="container" data-aos="fade-up">

                <div class="section-header">
                    <h2>Contact</h2>
                    <p>Reach out to us for more queries.</p>
                </div>

                <div class="row gx-lg-0 gy-4">

                    <div class="col-lg-4">

                        <div class="info-container d-flex flex-column align-items-center justify-content-center">
                            <div class="info-item d-flex">
                                <i class="bi bi-geo-alt flex-shrink-0"></i>
                                <div>
                                    <h4>Location:</h4>
                                    <p>300, 2nd, Mahavir Nagar, Maharani Farm, Durgapura, Jaipur, Rajasthan 302018</p>
                                </div>
                            </div>
                            <div class="info-item d-flex">
                                <i class="bi bi-envelope flex-shrink-0"></i>
                                <div>
                                    <h4>Email:</h4>
                                    <p>panditmukeshshastri@gmail.com</p>
                                </div>
                            </div>

                            <div class="info-item d-flex">
                                <i class="bi bi-phone flex-shrink-0"></i>
                                <div>
                                    <h4>Call:</h4>
                                    <p>+91 94148 24459</p>
                                </div>
                            </div>

                            <div class="info-item d-flex">
                                <i class="bi bi-clock flex-shrink-0"></i>
                                <div>
                                    <h4>Open Hours:</h4>
                                    <p>Mon-Sat: 9AM - 6PM</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-lg-8">
                        <form onSubmit={addContactData} method="post" role="form" class="php-email-form">
                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" onChange={handleSubmitContactData} required />
                                </div>
                                <div class="col-md-6 form-group mt-3 mt-md-0">
                                    <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" onChange={handleSubmitContactData} required />
                                </div>
                            </div>
                            <div class="form-group mt-3">
                                <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" onChange={handleSubmitContactData} required />
                            </div>
                            <div class="form-group mt-3">
                                <textarea class="form-control" name="message" rows="7" placeholder="Message" onChange={handleSubmitContactData} required></textarea>
                            </div>
                            <div class="my-3">
                                <div class="loading">Loading</div>
                                <div class="error-message"></div>
                                <div class="sent-message">Your message has been sent. Thank you!</div>
                            </div>
                            {!messageSent ? <div class="text-center"><button type="submit">Send Message</button></div> : <div class="my-3">
                                {/* <div class="loading">Loading</div>
                                <div class="error-message"></div> */}
                                <div class="text-center">Your message has been sent. Thank you!</div>
                            </div>}
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact;