import React, { useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import supabase from '../utils/connectSupabase';


const KundaliMatching = () => {
    const [kundaliMatchData, setKundaliMatchData] = useState({ email: '', phone: '', boy_name: '', girl_name: '', boy_time_of_birth: '', girl_time_of_birth: '', boy_date_of_birth: '', girl_date_of_birth: '', boy_place_of_birth: '', girl_place_of_birth: '' });

    const submitKundaliDetails = async (event) => {
        event.preventDefault();
        const { status } = await supabase.from('kundali_matching').insert({ ...kundaliMatchData });
        if (status === 201) {
            // setMessageSent(true)
            window.open(process.env.REACT_APP_HOROSCOPE_MATCHING_LINK, '_blank')
        }

    }

    const handleSubmitpKundaliData = (event) => {
        setKundaliMatchData({ ...kundaliMatchData, [event.target.name]: event.target.value })
    }
    return <>
        <Header />
        <div className="service">
            <div className="serviceCard">
                <h3>Submit your details</h3>
                <div className="row mt-3">
                    <div class="col-md-6 form-group">
                        <h6>Boy's details</h6>
                    </div>
                    <div class="col-md-6 form-group">
                        <h6>Girl's details</h6>
                    </div>
                </div>
                <div>
                    <form onSubmit={submitKundaliDetails} method="post" role="form" class="php-email-form">
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <input type="text" name="boy_name" class="form-control" id="boy_name" placeholder="Boy's Name" onChange={handleSubmitpKundaliData} required />
                            </div>
                            <div class="col-md-6 form-group mt-3 mt-md-0">
                                <input type="text" class="form-control" name="girl_name" id="girl_name" placeholder="Girl's Name" onChange={handleSubmitpKundaliData} required />
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6 form-group">
                                <input type="time" name="boy_time_of_birth" class="form-control" id="boy_time_of_birth" placeholder="Time of Birth" onChange={handleSubmitpKundaliData} required />
                            </div>
                            <div class="col-md-6 form-group mt-3 mt-md-0">
                                <input type="time" name="girl_time_of_birth" class="form-control" id="girl_time_of_birth" placeholder="Time of Birth" onChange={handleSubmitpKundaliData} required />
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6 form-group">
                                <input type="date" name="boy_date_of_birth" class="form-control" id="boy_date_of_birth" placeholder="Boy's date of Birth" onChange={handleSubmitpKundaliData} required />
                            </div>
                            <div class="col-md-6 form-group mt-3 mt-md-0">
                                <input type="date" name="girl_date_of_birth" class="form-control" id="girl_date_of_birth" placeholder="Girl's date of birth" onChange={handleSubmitpKundaliData} required />
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6 form-group">
                                <input type="text" name="boy_place_of_birth" class="form-control" id="boy_place_of_birth" placeholder="Boy's place of birth" onChange={handleSubmitpKundaliData} required />
                            </div>
                            <div class="col-md-6 form-group mt-3 mt-md-0">
                                <input type="text" name="girl_place_of_birth" class="form-control" id="girl_place_of_birth" placeholder="Girl's place of birth" onChange={handleSubmitpKundaliData} required />
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6 form-group">
                                <input type="phone" name="phone" class="form-control" id="phone" placeholder="Phone" onChange={handleSubmitpKundaliData} required />
                            </div>
                            <div class="col-md-6 form-group mt-3 mt-md-0">
                                <input type="email" class="form-control" name="email" id="email" placeholder="Email" onChange={handleSubmitpKundaliData} required />
                            </div>
                        </div>
                        <button className="mt-3 serviceCardSubmitButton" type="submit">Send</button>
                    </form>
                </div>
            </div>
            <p>Kundali matching, also known as Horoscope Matching, is a crucial step in Hindu culture for assessing marital compatibility. This ancient practice involves analyzing birth charts to determine compatibility factors like Nadi dosh, Mangal dosha, Gana milap score, and Bhakoot dosha. By inputting birth details, online tools generate Kundlis and assess compatibility based on astrological parameters. Expert astrologers recommend Kundali matching to ensure a harmonious and blissful married life.
            </p>
        </div>
        <Footer />
    </>
}

export default KundaliMatching;