import React from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Testimonials = () => {
    return (
        <section id="testimonials" className="testimonials">
            <div className="container" data-aos="fade-up">

                <div className="section-header">
                    <h2>Reviews</h2>
                    <p>Hear from some of our users.</p>
                </div>

                <Swiper
                    pagination={{
                        type: 'progressbar',
                    }}
                    navigation={true}
                    modules={[Navigation]}
                    className="mySwiper"
                    slidesPerView={3}
                    spaceBetween={30}
                >

                    <SwiperSlide>
                        <div className="testimonial-wrap">
                            <div className="testimonial-item">
                                <div className="d-flex align-items-center">
                                    <img src="assets/img/testimonials/testimonials-1.jpg" className="testimonial-img flex-shrink-0" alt="" />
                                    <div>
                                        <h3>Sumit Tak</h3>
                                        <div className="stars">
                                            <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                                className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                    <i className="bi bi-quote quote-icon-left"></i>
                                    For me Pandit ji has been guide and coach. All his predictions has been 100% correct. His solution to problems are very simple to follow. He is very simple and approachable and has been mentor to me for last 16 years. He has guided me like a father. I have high regard and respect for him.
                                    <i className="bi bi-quote quote-icon-right"></i>
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="testimonial-wrap">
                            <div className="testimonial-item">
                                <div className="d-flex align-items-center">
                                    <img src="assets/img/testimonials/testimonials-2.jpg" className="testimonial-img flex-shrink-0" alt="" />
                                    <div>
                                        <h3>Prasoon Sharma</h3>
                                        <div className="stars">
                                            <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                                className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                    <i className="bi bi-quote quote-icon-left"></i>
                                    Raj Jyotishi Pandit Mukesh Shastri has very thorough knowledge of astrology and vaastu shastra along with great experience. I always look forward for his direction and advice in critical matters of my personal and professional life. 🙏
                                    <i className="bi bi-quote quote-icon-right"></i>
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="testimonial-item">
                            <div className="d-flex align-items-center">
                                <img src="assets/img/testimonials/testimonials-3.jpg" className="testimonial-img flex-shrink-0" alt="" />
                                <div>
                                    <h3>Gaurav Mathur</h3>
                                    <div className="stars">
                                        <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                            className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                    </div>
                                </div>
                            </div>
                            <p>
                                <i className="bi bi-quote quote-icon-left"></i>
                                Always listens with heart and is so supportive just like a father figure to me when I had needed the most. To this day he guides me like his own son and family.
                                Helpful and source of inspiration
                                <i className="bi bi-quote quote-icon-right"></i>
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="testimonial-wrap">
                            <div className="testimonial-item">
                                <div className="d-flex align-items-center">
                                    <img src="assets/img/testimonials/testimonials-5.jpg" className="testimonial-img flex-shrink-0" alt="" />
                                    <div>
                                        <h3>Nikhil Sharma</h3>
                                        <div className="stars">
                                            <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                                className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                    <i className="bi bi-quote quote-icon-left"></i>
                                    I visited Raj Jyotishi Pandit Mukesh Shastri Ji at his residence for some advice on personal matters.Whatever he said turned out to be 100% accurate and moreover he predicted a few more events in future which also turned out to be true.Since then me and family are regular viewers of his Morning show Good Luck Tips on  First India Channel..I would certainly rate him one of the best astrologers of the entire country.....
                                    <i className="bi bi-quote quote-icon-right"></i>
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </section >
    )

}

export default Testimonials;