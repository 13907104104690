import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from "../components/header";
import Footer from "../components/footer";
import cosmic from '../utils/cosmicConnect';
import DOMPurify from 'dompurify';

const Article = () => {
    const [article, setArticle] = useState({});
    let { id } = useParams();

    async function fetchArticle() {
        const articleData = await cosmic.objects.findOne({
            type: 'blogs',
            slug: id
        })

        setArticle(articleData.object);
    }

    useEffect(() => {
        fetchArticle();
    }, [])


    function RichTextComponent(richTextString) {
        const sanitizedHTML = DOMPurify.sanitize(richTextString);

        return (
            <div className="content" dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
        );
    }
    return (
        <>
            <Header />
            <div className='breadcrumbs'>
                <div class="page-header d-flex align-items-center">
                    <div class="container position-relative">
                        <div class="row d-flex justify-content-center">
                            <div class="col-lg-6 text-center">
                                <h2>Let's hear from our famous Astrologer</h2>
                                <p>Here are some latest articles from Raj Jyotishi Pandit Mukesh Shastri. </p>
                            </div>
                        </div>
                    </div>
                </div>
                <nav>
                    <div class="container">
                        <ol>
                            <li><a href="/">Home</a></li>
                            <li>Blog Details</li>
                        </ol>
                    </div>
                </nav>
                <section id="blog" className="blog">
                    <div className="container aos-init aos-animate" data-aos="fade-up">

                        <div className="row">


                            <article className="blog-details">

                                <div className="post-img">
                                    <img src={article.metadata?.blog_image?.url} alt="" className="img-fluid" />
                                </div>

                                <h2 className="title">{article.metadata?.blog_heading}</h2>

                                <div className="meta-top">
                                    <ul>
                                        <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-details.html">Pandit Mukesh Shastri</a></li>
                                        <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-details.html"><time datetime="2020-01-01">{convertDateTime(article.published_at)}</time></a></li>
                                    </ul>
                                </div>

                                {RichTextComponent(article.metadata?.blog_description_rich_text)}

                            </article>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}

const convertDateTime = (date) => {
    const dateTime = new Date(date);

    // Format the date and time
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDateTime = dateTime.toLocaleString('en-US', options);

    return formattedDateTime;
}

export default Article;