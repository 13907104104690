import './App.css';
import './index.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Home from './pages/home'
import Blog from "./pages/blog"
import Article from "./pages/article";
import { Routes, Route } from 'react-router-dom';
import ServicePayment from './pages/servicePayment';
import PersonalisedKundali from "./pages/personalisedKundali";
import KundaliMatching from './pages/kundaliMatching';
import PrivacyPolicy from './pages/privacyPolicy';
import TermsAndConditions from './pages/termsConditions';
import PaymentSuccess from './pages/paymentSuccess';
import PaymentFailure from './pages/paymentFailure';
import Gallery from './pages/gallery';
import './index.css';


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/article/:id" element={<Article />} />
        <Route path='/service/:id' element={<ServicePayment />}></Route>
        <Route path='/personalisedKundali' element={<PersonalisedKundali />}></Route>
        <Route path='/kundaliMatching' element={<KundaliMatching />}></Route>
        <Route path='/privacy-policy' element={<PrivacyPolicy />}></Route>
        <Route path='/terms-conditions' element={<TermsAndConditions />}></Route>
        <Route path='/payment-success' element={<PaymentSuccess />}></Route>
        <Route path='/payment-failure' element={<PaymentFailure />}></Route>
        <Route path='/gallery' element={<Gallery />}></Route>
      </Routes>
    </div>
  );
}

export default App;
