import React from 'react';
import Header from "../components/header";
import Footer from "../components/footer";

const Gallery = () => {
    const imageNames = [
        'pooj1.jpeg',
        'pooj2.jpeg',
        'pooj4.jpeg',
        'pooj5.jpeg',
        'pooj6.jpeg',
        'pooj7.jpeg',
        'pooj8.jpeg',
    ]
    return (<>
        <Header />
        <h1 className='gallery-heading'>Highlights from the pooja ceremony we performed recently with our valued clients</h1>
        <div className="image-grid-gallery">
            {imageNames.map((imageName, index) => (
                <img
                    key={index}
                    src={`${process.env.PUBLIC_URL}/gallery/${imageName}`}
                    alt={`Image ${index + 1}`}
                />
            ))}
            <video width="350px" controls>
                <source src={`${process.env.PUBLIC_URL}/gallery/pooj9.mp4`} type="video/mp4">
                </source>
            </video>
        </div>
        <Footer />
    </>)
}


export default Gallery;