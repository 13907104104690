import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import "./index.css";

const TermsAndConditions = () => {
    return <>
        <Header />
        <div className="privacy-policy">
            <h5>1. Introduction</h5>
            <p>These terms and conditions outline the rules and regulations for the use of [Company Name]'s Website, located at [website URL].</p>

            <h5>2. Acceptance of Terms</h5>
            <p>By accessing this website, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, please do not use our website.</p>

            <h5>3. Intellectual Property Rights</h5>
            <p>The content on this website, including but not limited to text, graphics, logos, images, and software, is the property of [Company Name] and is protected by applicable intellectual property laws.</p>

            <h5>4. User Conduct</h5>
            <p>You agree to use this website in a manner that does not violate any applicable laws or regulations. You further agree not to engage in any activity that could damage, disable, overburden, or impair the website or interfere with any other party's use and enjoyment of the website.</p>

            <h5>5. Limitation of Liability</h5>
            <p>[Company Name] shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or related to the use of this website or any information contained herein.</p>

            <h5>6. Governing Law</h5>
            <p>These terms and conditions shall be governed by and construed in accordance with the laws of [Governing Law Jurisdiction], without giving effect to any principles of conflicts of law.</p>

            <h5>7. Changes to Terms and Conditions</h5>
            <p>[Company Name] reserves the right to modify these terms and conditions at any time without prior notice. Your continued use of the website following any changes to these terms and conditions constitutes your acceptance of such changes.</p>
        </div>
        <Footer />
    </>
}

export default TermsAndConditions;