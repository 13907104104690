import React from "react";
import Header from '../components/header';
import HeroBanner from '../components/heroBanner';
import About from '../components/about';
import Services from '../components/services';
import Testimonials from '../components/testimonials';
import Products from '../components/products';
import Blog from '../components/blog';
import Contact from '../components/contact';
import Footer from '../components/footer';

const Home = () => {
    return (
        <>
            <Header />
            <HeroBanner />
            <About />
            <Services />
            <Testimonials />
            <Products />
            <Blog />
            <Contact />
            <Footer />
        </>
    )
}

export default Home;