import React from "react";
import { Link } from 'react-router-dom';
import ScrollToTop from "../utils/scrollToTop";


const Footer = () => {
    return (
        <footer id="footer" className="footer">
            <div className="container">
                <div className="col-lg-5 col-md-12 footer-info">
                    <a href="index.html" className="logo d-flex align-items-center">
                        <span>Good Luck Tips</span>
                    </a>
                    <p>Unlock the mysteries of your destiny today.</p>
                    <div className="social-links d-flex mt-4">
                        <a href="#" className="twitter"><i className="bi bi-twitter"></i></a>
                        <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
                        <a href="#" className="instagram"><i className="bi bi-instagram"></i></a>
                        <a href="#" className="linkedin"><i className="bi bi-linkedin"></i></a>
                    </div>
                </div>

                <div className="col-lg-2 col-6 footer-links">
                    <h4>Useful Links</h4>
                    <ul>
                        <ScrollToTop />
                        <li><Link to={'/'} preventScrollReset={true}>Home</Link></li>
                        <li><Link to={'/#about'} preventScrollReset={true}>About us</Link></li>
                        <li><Link to={'/#services'} preventScrollReset={true}>Services</Link></li>
                        <li><Link to={'/terms-conditions'} preventScrollReset={true}>Terms of service</Link></li>
                        <li><Link to={'/privacy-policy'} preventScrollReset={true}>Privacy policy</Link></li>
                    </ul>
                </div>


                <div className="col-lg-2 col-6 footer-links">
                    <h4>Our Services</h4>
                    <ul>
                        <li>Personalised Kundali</li>
                        <li>Kundali Matching</li>
                        <li>Telephonic Consultation</li>
                        <li>Video Consultation</li>
                        <li>Online Pooja</li>
                        <li>Online Muhurat</li>
                        <li>Online Muhurat</li>
                        <li>Daily Updates</li>
                        <li>Gems</li>
                    </ul>
                </div>


                <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                    <h4>Contact Us</h4>

                    <p>
                        300, 2nd, Mahavir Nagar, <br />
                        Maharani Farm, Durgapura<br />
                        Jaipur, Rajasthan 302018<br /><br />
                        <strong>Phone:</strong>+91 94148 24459<br />
                        <strong>Email:</strong>panditmukeshshastri@gmail.com<br></br>
                    </p>
                </div>
            </div>
        </footer>
    )
}
export default Footer;