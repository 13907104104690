
export default function sendMessageToWhatsapp(contactData) {
    const { name, email, subject, message } = contactData;
    let messageBody = `
Jai Shri Krishna!

Thank you, ${name}, for completing the contact form on our website. We are pleased to connect you with Raj Jyotishi Pandit Mukesh Shastri Ji via WhatsApp.
   
Here are the details of your submission:
    
Name: ${name}
Email: ${email}
Reason for Contact: ${subject}
Detailed Message: ${message}

We look forward to assisting you!
`
    let whatsAppLink = `https://wa.me/${process.env.REACT_APP_ASTROLOGER_CONTACT_NUMBER}?text=${encodeURIComponent(messageBody)}`;
    window.open(whatsAppLink, "_blank");
}