import React from "react";
import { useNavigate } from 'react-router-dom';
import Header from "../components/header"
import Footer from '../components/footer';
import "../index.css"

const PaymentFailure = () => {
    const navigate = useNavigate();

    const handleGoToHome = () => {
        navigate('/#services');
    };

    return (
        <>
            <Header />
            <div className="flex flex-col items-center justify-center h-screen bg-gray-100 payment-results">
                <div className="bg-white p-8 rounded-lg shadow-md">
                    <h1 className="text-4xl font-bold text-red-500 mb-4">Payment Failed</h1>
                    <p className="text-gray-700 mb-6">
                        We're sorry, but your payment was unsuccessful.
                        <br />
                        <br />
                        Please try a different payment method or contact our support team for assistance by writing to us on <b><u>panditmukeshshastri@gmail.com</u></b>
                    </p>
                    <button
                        className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                        onClick={handleGoToHome}
                    >
                        Go To Home
                    </button>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default PaymentFailure;