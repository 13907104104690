import React from 'react';
import { Link } from 'react-router-dom';
import ScrollToTop from '../utils/scrollToTop';

const Header = () => {

    return (
        <>
            <section id="topbar" className="topbar d-flex align-items-center">
                <div className="container d-flex justify-content-center justify-content-md-between">
                    <div className="contact-info d-flex align-items-center">
                        <i className="bi bi-envelope d-flex align-items-center"><a href="mailto:contact@example.com">panditmukeshshastri@gmail.com</a></i>
                        <i className="bi bi-phone d-flex align-items-center ms-4"><span>+91 94148 24459</span></i>
                    </div>
                    <div className="social-links d-none d-md-flex align-items-center">
                        <a href="https://www.youtube.com/user/panditmukeshshastri" className="youtube"><i className="bi bi-youtube"></i></a>
                        <a href="https://twitter.com/MukeshTips" className="twitter"><i className="bi bi-twitter"></i></a>
                        <a href="https://www.facebook.com/panditmukesh.shastri/" className="facebook"><i className="bi bi-facebook"></i></a>
                        <a href="#" className="instagram"><i className="bi bi-instagram"></i></a>
                    </div>
                </div>
            </section >
            <header id="header" class="header d-flex align-items-center">

                <div class="container-fluid container-xl d-flex align-items-center justify-content-between">
                    <a href="/" class="logo d-flex align-items-center">
                        <img src="./assets/astro-logo.webp" alt="" />
                        <h1>Good Luck Tips<span>.</span></h1>
                    </a>
                    <nav id="navbar" class="navbar">
                        <ul>
                            <ScrollToTop />
                            <li><Link to="/#hero" preventScrollReset={true}>Home</Link></li>
                            <li><Link to="/#about" preventScrollReset={true}>About</Link></li>
                            <li><Link to="/#services" preventScrollReset={true}>Services</Link></li>
                            <li><Link to="/#gems" preventScrollReset={true}>Gems</Link></li>
                            <li><Link to="/blog" preventScrollReset={true}>Blog</Link></li>
                            <li><Link to="/#contact" preventScrollReset={true}>Contact</Link></li>
                            <li><Link to="/gallery" preventScrollReset={true}>Gallery</Link></li>
                        </ul>
                    </nav>

                    <i class="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
                    <i class="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>

                </div>
            </header>
        </>
    )
}

export default Header;